import ApiConnector from "../connector/ApiConnector";
import { useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import UtilService from "../service/UtilService";
import AuthService from "../service/AuthService";
import moment from "moment";
import { useLocation } from "react-router-dom";
import NoteService from "../service/NoteService";
import { useDebounce } from "use-debounce";
import RouteUtilService from "../service/RouteUtilService";

const Component = (props) => {
  const STATUS_CREATED = "CREATED";
  const STATUS_UPDATED = "UPDATED";
  const STATUS_DELETED = "DELETED";

  const NOTE_TEMPLATE = {
    author: AuthService.getUserName(),
    created: moment().toISOString(false),
    targetPath: "",
    targetId: "",
    note: "",
    status: STATUS_CREATED,
  };

  const [locationSearch, updateLocationSearch] = useImmer({
    targetPath: "",
    targetId: "",
    maxTargetId: null,
    isEnforceUserToUpdateNote: false,
  });
  const [filterConfig, updateFilterConfig] = useImmer({
    targetPathStartsWith: "",
    pageSize: 40,
  });
  const [debounceFilterConfig] = useDebounce(filterConfig, 300);
  const [notes, updateNotes] = useImmer([]);
  const [isInitialized, updateIsInitialized] = useImmer(false);
  const isNewNoteCreated = useRef(false);
  const location = useLocation();

  /**
   * Initialize component - setup query parameters
   */
  useEffect(async () => {
    const targetPath = RouteUtilService.getParam(location, "targetPath");
    const targetId = RouteUtilService.getParam(location, "targetId");
    const isEnforceUserToUpdateNote = RouteUtilService.getParam(
      location,
      "isEnforceUserToUpdateNote",
    );

    updateLocationSearch((d) => {
      d.targetPath = targetPath;
      d.targetId = targetId;
      d.isEnforceUserToUpdateNote = isEnforceUserToUpdateNote;
    });

    updateFilterConfig((d) => {
      d.targetPathStartsWith = targetPath;
      d.targetIdMax = targetId;
    });

    updateIsInitialized(true);
  }, [location]);

  /**
   * Defines component unmount
   */
  useEffect(() => {
    return () => {
      if (
        locationSearch.isEnforceUserToUpdateNote &&
        !isNewNoteCreated.current
      ) {
        window.alert(
          "You have not specified changes you've done. Please specify the changes later or be sure it is not needed.",
        );
      }
    };
  }, [locationSearch]);

  /**
   * Reaload notes
   */

  useEffect(async () => {
    if (!isInitialized) {
      return;
    }
    await loadNotes();

    const isNewNote = locationSearch.targetPath;
    if (isNewNote) {
      addNote();
    }
  }, [debounceFilterConfig]);

  const loadNotes = async () => {
    const data = await ApiConnector.getNotes(
      filterConfig.targetPathStartsWith,
      filterConfig.targetIdMax,
      filterConfig.pageSize,
    );
    updateNotes(data.data);
  };

  const addNote = () => {
    let note = JSON.parse(JSON.stringify(NOTE_TEMPLATE));
    note.targetId = locationSearch.targetId;
    note.targetPath = locationSearch.targetPath;
    note.status = STATUS_CREATED;
    updateNotes((d) => {
      d.unshift(note);
    });
  };

  const save = async () => {
    const createdNotes = notes.filter(
      (n) => n.status === STATUS_CREATED && n.note,
    );
    const updatedNotes = notes.filter((n) => n.status === STATUS_UPDATED);
    const deletedNotes = notes.filter((n) => n.status === STATUS_DELETED);

    const result = window.confirm(
      "Do you want to make changes in Notes?\n\n" +
        createdNotes.length +
        " will be created\n" +
        updatedNotes.length +
        " will be updated\n" +
        deletedNotes.length +
        " will be deleted\n",
    );

    if (!result) {
      return;
    }

    try {
      for (let n of createdNotes) await ApiConnector.createNote(n);
      for (let n of updatedNotes) await ApiConnector.updateNote(n);
      for (let n of deletedNotes) await ApiConnector.deleteNote(n);
      await loadNotes();
      isNewNoteCreated.current = true;
    } catch (e) {
      window.alert(
        "An error occured. Some of the notes has been not processed.",
      );
    }
  };

  return (
    <>
      <header className="subheader">
        <h1>Notes</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Latest notes</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right">
              <a className="fa-regular fa-floppy-disk " onClick={save} />
            </h2>
          </div>
        </div>

        <details>
          <summary>Filtering parameters</summary>
          <table className="config-table">
            <thead>
              <tr>
                <th>Filter</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Note description [starts with]</td>
                <td>
                  <input
                    type="text"
                    value={filterConfig.targetPathStartsWith}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.targetPathStartsWith = e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Target id [max value]</td>
                <td>
                  <input
                    type="text"
                    value={filterConfig.targetIdMax}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.targetIdMax = e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Page size</td>
                <td>
                  <input
                    type="number"
                    value={filterConfig.pageSize}
                    min={1}
                    max={1000}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.pageSize = e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </details>

        {notes.map((note, index) => (
          <div
            key={index}
            className={
              note.status === STATUS_DELETED
                ? "warn"
                : note.status === STATUS_UPDATED
                ? "hint"
                : ""
            }
          >
            <div className="row">
              <div className="col">
                <b>
                  {/*{note.status === STATUS_CREATED ? "Create new note: " : ""}*/}
                  {NoteService.noteTargetPathToReadable(
                    note.targetPath,
                    note.targetId,
                  )}
                </b>
                <br />
                {note.status === STATUS_CREATED ? (
                  <>New note</>
                ) : (
                  <small>
                    {UtilService.isoDateToReadable(note.created)}, {note.author}{" "}
                    <br />
                    {note.updated ? (
                      <>
                        Updated {UtilService.isoDateToReadable(note.updated)},{" "}
                        {note.updatedAuthor}
                      </>
                    ) : (
                      ""
                    )}
                  </small>
                )}
              </div>
              <div className="col-55">
                <span className="float-right">
                  <a
                    className={
                      "fa-regular fa-trash-can " +
                      (note.author === "PABLO" || note.status === "CREATED"
                        ? "gray"
                        : "")
                    }
                    onClick={() =>
                      updateNotes((d) => {
                        d[index].status = STATUS_DELETED;
                      })
                    }
                  />
                </span>
                <textarea
                  rows="2"
                  value={note.note}
                  onChange={(e) =>
                    updateNotes((d) => {
                      d[index].note = e.target.value;
                      d[index].status =
                        d[index].status === STATUS_CREATED
                          ? d[index].status
                          : STATUS_UPDATED;
                      d[index].updatedAuthor = AuthService.getUserName();
                    })
                  }
                  placeholder="Here you can write new note. Blank note will be not saved."
                ></textarea>
              </div>
            </div>
            <br />
            <br />
          </div>
        ))}
      </main>
    </>
  );
};

export default Component;
