import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup <small>[minutes]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupMinutes}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupMinutes =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Declined transactions count min</td>
            <td>
              <input
                type="number"
                value={data.declinedCountMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.declinedCountMin =
                      e.target.value > 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Score</td>
            <td>
              <input
                type="number"
                value={data.score}
                onChange={(e) =>
                  updateData((d) => {
                    d.score = e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/config/auto-allow">View detail</Link>
    </>
  );
};

export default Component;
