const Component = ({ dayOfWeekArray, onChange, breakLine }) => {
  const toggleDayOfWeek = (dayOfWeek) => {
    const isPresent = dayOfWeekArray.includes(dayOfWeek);
    if (isPresent) {
      onChange(dayOfWeekArray.filter((d) => d !== dayOfWeek));
    } else {
      onChange([...dayOfWeekArray, dayOfWeek]);
    }
  };

  return (
    <>
      {[
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ].map((dayOfWeek, idx) => (
        <span key={idx}>
          <input
            type="checkbox"
            checked={dayOfWeekArray.includes(dayOfWeek)}
            onChange={(e) => toggleDayOfWeek(dayOfWeek)}
          />
          <label>
            {" "}
            {dayOfWeek.substring(0, 2)}
            {idx < 6 ? "," : <>&nbsp;&nbsp;&nbsp;</>}{" "}
          </label>
        </span>
      ))}
    </>
  );
};

export default Component;
