import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Time range header</th>
            <th colSpan={2}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            {data.daytimeScoreTimes.map((time, index) => (
              <td key={index}>
                <input
                  type="time"
                  value={time}
                  onChange={(e) =>
                    updateData((d) => {
                      d.daytimeScoreTimes[index] = e.target.value;
                    })
                  }
                />
              </td>
            ))}
            <th>
              <input type="time" value={"23:59"} disabled={true} />
            </th>
          </tr>
        </tbody>
      </table>
      <table className="config-table">
        <thead>
          <tr>
            <th>Weekday</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {data.daytimeScores.map((daytimeScore, index0) => (
            <tr key={daytimeScore.dayOfWeek}>
              <td key={index0}>{daytimeScore.dayOfWeek}</td>
              {daytimeScore.score.map((sc, index1) => (
                <td key={index1}>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.1"
                    value={sc}
                    onChange={(e) =>
                      updateData((d) => {
                        d.daytimeScores[index0].score[index1] = e.target.value;
                      })
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
