import UtilService from "../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Treshold name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map((entry) => (
            <tr key={entry[0]}>
              <td>{UtilService.camelCaseToReadable(entry[0])}</td>
              <td>
                <input
                  type="number"
                  min="0"
                  max="10"
                  step="0.1"
                  value={entry[1]}
                  onChange={(e) =>
                    updateData((d) => {
                      d[entry[0]] = e.target.value;
                    })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
