import moment from "moment";

const Component = {
  ruleSuppressedRemainingMinutes: function (ruleSuppressConfig, ruleName) {
    const diffMs =
      moment(ruleSuppressConfig[ruleName]).valueOf() - moment().valueOf();
    return Math.round(diffMs / 1000 / 60);
  },
  isRuleSuppressed: function (ruleSuppressConfig, ruleName) {
    const offsetDateTime = ruleSuppressConfig[ruleName];
    if (offsetDateTime === null) {
      return false;
    }
    return moment(offsetDateTime).valueOf() > moment();
  },
};

export default Component;
