import { useImmer } from "use-immer";
import { useEffect } from "react";
import UtilService from "../service/UtilService";
import RuleSuppressService from "../service/RuleSuppressService";
import moment from "moment/moment";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const toggleRuleSuppressed = (ruleName) => {
    const isSuppress = RuleSuppressService.isRuleSuppressed(data, ruleName);
    const newValue = isSuppress ? null : moment().add(1, "hours");
    updateData((d) => {
      d[ruleName] = newValue;
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Rule name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map((entry) => (
            <tr
              className={
                RuleSuppressService.isRuleSuppressed(data, entry[0])
                  ? "warn"
                  : ""
              }
            >
              <td>{UtilService.camelCaseToReadable(entry[0])}</td>
              <td>
                <input
                  type="checkbox"
                  checked={RuleSuppressService.isRuleSuppressed(data, entry[0])}
                  onChange={() => toggleRuleSuppressed(entry[0])}
                />
                &nbsp;
                {RuleSuppressService.isRuleSuppressed(data, entry[0])
                  ? "Suppress is active (" +
                    RuleSuppressService.ruleSuppressedRemainingMinutes(
                      data,
                      entry[0],
                    ) +
                    " minutes remaining)"
                  : "Suppress inactive"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
