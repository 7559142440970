import ApiConnector from "../connector/ApiConnector";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import UtilService from "../service/UtilService";

const Component = (props) => {
  const [newEntry, updateNewEntry] = useImmer({ value: 0, status: "WARM" });
  const [data, updateData] = useImmer(null);
  const [csvUrl, updateCsvUrl] = useImmer("");

  useEffect(() => {
    loadOdometers();
  }, []);

  const loadOdometers = () => {
    ApiConnector.getOdometers().then((t) => {
      t = t.sort((a, b) => a.value - b.value);
      t = t.sort((a, b) => a.status.localeCompare(b.status));
      updateData(t);

      // Pre-generate csv file so it can be downloaded on single click
      const csvObjectUrl =
        UtilService.convertJsonArrayToDownloadableCsvFileUrl(t);
      updateCsvUrl(csvObjectUrl);
    });
  };

  const save = async () => {
    const result = window.confirm(
      "New odometer values will be set. This operation can't be undone.",
    );
    if (result) {
      await ApiConnector.replaceOdometers(data);
      loadOdometers();
    }
  };

  return (
    <>
      <header className="subheader">
        <h1>Config - Odometer rule </h1>
      </header>
      <main>
        {data && (
          <>
            <div className="row">
              <div className="col">
                <h2>Odometer values</h2>
              </div>
              <div className="col">
                <h2 className="float-right">
                  <a className="fa-regular fa-floppy-disk" onClick={save}></a>
                </h2>
              </div>
            </div>
            <a href={csvUrl} download={"odometers.csv"}>
              Export to CSV
            </a>
            <table className="config-table table-with-button">
              <thead>
                <tr>
                  <th>Odometer value</th>
                  <th>Status</th>
                  <th>Created by auto update</th>
                  <th>
                    <a
                      className="fa-solid fa-plus"
                      onClick={(e) =>
                        updateData((d) => {
                          d.unshift(newEntry);
                        })
                      }
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((odometer, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        value={odometer.value}
                        onChange={(e) =>
                          updateData((d) => {
                            d[index].value = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={odometer.status}
                        onChange={(e) =>
                          updateData((d) => {
                            d[index].status = e.target.value;
                          })
                        }
                      >
                        <option value="WARM">WARM</option>
                        <option value="HOT">HOT</option>
                        <option value="NEW_WARM">NEW_WARM</option>
                        <option value="NEW_HOT">NEW_HOT</option>
                      </select>
                    </td>
                    <td>{odometer.isCreatedByAutoUpdate ? "YES" : "-"}</td>
                    <td>
                      <a
                        className="fa-solid fa-trash-can"
                        onClick={(e) =>
                          updateData((d) => {
                            d.splice(index, 1);
                          })
                        }
                      ></a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
