import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import UtilService from "../service/UtilService";
import { useEffect } from "react";
import OdometerAutoUpdateGeneralConfig from "../component/OdometerAutoUpdateGeneralConfig";
import HotspotAutoUpdateGeneralConfig from "../component/HotspotAutoUpdateGeneralConfig";
import RuleSuppressGeneralConfig from "../component/RuleSuppressGeneralConfig";
import DaytimeAutoUpdateGeneralConfig from "../component/DaytimeAutoUpdateGeneralConfig";
import VolumeAutoUpdateGeneralConfig from "../component/VolumeRuleAutoUpdateGeneralConfig";
import CustomerOriginAutoUpdateGeneralConfig from "../component/CustomerOriginAutoUpdateGeneralConfig";
import CardAgeAutoUpdateGeneralConfig from "../component/CardAgeAutoUpdateGeneralConfig";
import { Link } from "react-router-dom";
import Note from "../component/Note";
import AutoDeclineAutoUpdateGeneralConfig from "../component/AutoDeclineAutoUpdateGeneralConfig";
import NotificationGeneralConfig from "../component/NotificationGeneralConfig";
import GeneralConfigService from "../service/GeneralConfigService";
import FacilityAutoUpdateGeneralConfig from "../component/FacilityAutoUpdateGeneralConfig";
import CountryAutoUpdateGeneralConfig from "../component/CountryAutoUpdateGeneralConfig";

const Component = (props) => {
  const [data, updateData] = useImmer(null);
  useEffect(() => {
    loadLatestGeneralConfig();
  }, []);

  const loadLatestGeneralConfig = () => {
    ApiConnector.getLatestGeneralConfig().then((t) => {
      updateData(t);
    });
  };

  const createGeneralConfig = async () => {
    const newConfig = await GeneralConfigService.createGeneralConfig(
      data,
      data.id,
    );
    if (!newConfig) {
      return;
    }
    updateData(newConfig);
  };

  return (
    data && (
      <>
        <header className="subheader">
          <h1>Admin</h1>
        </header>
        <main>
          <div className="row">
            <div className="col">
              <h2>General config</h2>
            </div>
            <div className="col">
              <h2 className="float-right">
                <Note noteTargetPath="generalConfig" noteTargetId={data.id} />
                &nbsp;&nbsp;&nbsp;
                <a
                  className="fa-regular fa-floppy-disk "
                  onClick={(e) => createGeneralConfig()}
                />
              </h2>
            </div>
          </div>

          <table className="config-table">
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Id</td>
                <td>{data.id}</td>
              </tr>
              <tr>
                <td>Created</td>
                <td>{UtilService.isoDateToReadable(data.created)}</td>
              </tr>
            </tbody>
          </table>

          <h3>
            Rule suppress
            <Note
              className="float-right"
              noteTargetPath="generalConfig.ruleSuppress"
              noteTargetId={data.id}
            />
          </h3>
          <RuleSuppressGeneralConfig
            config={data.generalConfig.ruleSuppressConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.ruleSuppressConfig = data;
              })
            }
          ></RuleSuppressGeneralConfig>

          <h3>
            Merchant suppress
            <Note
              className="float-right"
              noteTargetPath="generalConfig.merchantSuppress"
              noteTargetId={data.id}
            />
          </h3>
          <table className="config-table">
            <thead>
              <tr>
                <th>Attribute</th>
                <th>
                  Value <small>[substrings]</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Merchant codes</td>
                <td>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(
                      data.generalConfig.suppressConfig.merchantCodeSuppresses,
                      true,
                    )}
                    onChange={(e) =>
                      updateData((d) => {
                        d.generalConfig.suppressConfig.merchantCodeSuppresses =
                          UtilService.csvToStringArray(e.target.value);
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Terminal ids</td>
                <td>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(
                      data.generalConfig.suppressConfig.terminalIdSuppresses,
                      true,
                    )}
                    onChange={(e) =>
                      updateData((d) => {
                        d.generalConfig.suppressConfig.terminalIdSuppresses =
                          UtilService.csvToStringArray(e.target.value);
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h3>
            Notification of customer
            <Note
              className="float-right"
              noteTargetPath="generalConfig.notification"
              noteTargetId={data.id}
            />
          </h3>
          <NotificationGeneralConfig
            config={data.generalConfig.notificationConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.notificationConfig = data;
              })
            }
          ></NotificationGeneralConfig>

          <h3>
            Auto decline
            <Note
              className="float-right"
              noteTargetPath="generalConfig.autoDecline"
              noteTargetId={data.id}
            />
          </h3>
          <p>
            Definition of hard rules used to automatically decline transaction.
          </p>
          <Link className="" to="/admin/auto-decline">
            View detail
          </Link>

          <h3>
            Auto decline auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.autoDeclineAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <AutoDeclineAutoUpdateGeneralConfig
            config={data.generalConfig.autoDeclineAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.autoDeclineAutoUpdateConfig = data;
              })
            }
          ></AutoDeclineAutoUpdateGeneralConfig>

          <h3>
            Card age rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.cardAgeRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <CardAgeAutoUpdateGeneralConfig
            config={data.generalConfig.cardAgeAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.cardAgeAutoUpdateConfig = data;
              })
            }
          ></CardAgeAutoUpdateGeneralConfig>

          <h3>
            Country rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.countryRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <CountryAutoUpdateGeneralConfig
            config={data.generalConfig.countryAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.countryAutoUpdateConfig = data;
              })
            }
          ></CountryAutoUpdateGeneralConfig>

          <h3>
            Customer origin rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.customerOriginRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <CustomerOriginAutoUpdateGeneralConfig
            config={data.generalConfig.customerOriginAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.customerOriginAutoUpdateConfig = data;
              })
            }
          ></CustomerOriginAutoUpdateGeneralConfig>

          <h3>
            Daytime rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.daytimeRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <DaytimeAutoUpdateGeneralConfig
            config={data.generalConfig.daytimeAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.daytimeAutoUpdateConfig = data;
              })
            }
          ></DaytimeAutoUpdateGeneralConfig>

          <h3>
            Hotspot rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.hotspotRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <HotspotAutoUpdateGeneralConfig
            config={data.generalConfig.hotspotAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.hotspotAutoUpdateConfig = data;
              })
            }
          ></HotspotAutoUpdateGeneralConfig>

          <h3>
            Facility rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.facilityRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <FacilityAutoUpdateGeneralConfig
            config={data.generalConfig.facilityAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.facilityAutoUpdateConfig = data;
              })
            }
          ></FacilityAutoUpdateGeneralConfig>

          <h3>
            Odometer rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.odometerRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <OdometerAutoUpdateGeneralConfig
            config={data.generalConfig.odometerAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.odometerAutoUpdateConfig = data;
              })
            }
          ></OdometerAutoUpdateGeneralConfig>

          <h3>
            Volume rule auto update
            <Note
              className="float-right"
              noteTargetPath="generalConfig.volumeRuleAutoUpdate"
              noteTargetId={data.id}
            />
          </h3>
          <VolumeAutoUpdateGeneralConfig
            config={data.generalConfig.volumeAutoUpdateConfig}
            onChange={(data) =>
              updateData((d) => {
                d.generalConfig.volumeAutoUpdateConfig = data;
              })
            }
          ></VolumeAutoUpdateGeneralConfig>
        </main>
      </>
    )
  );
};

export default Component;
