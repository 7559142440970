import { Link } from "react-router-dom";
import NoteService from "../service/NoteService";

const Component = ({ className, noteTargetPath, noteTargetId }) => {
  const route = NoteService.createNoteRouteUrl(noteTargetPath, noteTargetId);

  return (
    <span className={className + " "}>
      <Link style={{}} className="fa-regular fa-note-sticky" to={route}></Link>
    </span>
  );
};

export default Component;
