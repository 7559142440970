const Component = (props) => {
  return (
    <>
      <header className="subheader">
        <h1>Simulation</h1>
      </header>
      <main></main>
    </>
  );
};

export default Component;
