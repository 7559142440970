import UtilService from "../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreConfigTemplate = {
    minutesMin: 0,
    minutesMax: 0,
    countMin: 0,
    countMax: 0,
    scoreOnMatch: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScoreConfig = () => {
    updateData((draft) => {
      draft.scoreConfigs.push(structuredClone(scoreConfigTemplate));
    });
  };

  const removeScoreConfig = (index) => {
    updateData((draft) => {
      draft.scoreConfigs.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reject reason codes</td>
            <td>
              <input
                type="text"
                value={UtilService.arrayToCsv(data.rejectReasonCodes)}
                onChange={(e) =>
                  updateData((d) => {
                    d.rejectReasonCodes = UtilService.csvToStringArray(
                      e.target.value,
                    );
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Count only unsuccessful consecutive auths</td>
            <td>
              <input
                type="checkbox"
                checked={data.countOnlyUnsuccessfulConsecutiveAuths}
                onChange={(e) =>
                  updateData((d) => {
                    d.countOnlyUnsuccessfulConsecutiveAuths =
                      !d.countOnlyUnsuccessfulConsecutiveAuths;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Count only unique pans</td>
            <td>
              <input
                type="checkbox"
                checked={data.countOnlyUniquePans}
                onChange={(e) =>
                  updateData((d) => {
                    d.countOnlyUniquePans = !d.countOnlyUniquePans;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>
              History min <small>[minute]</small>
            </th>
            <th>
              History max <small>[minute]</small>
            </th>
            <th>Count min</th>
            <th>Count max</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScoreConfig}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scoreConfigs.map((scoreConfig, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  min={-30}
                  max={scoreConfig.minutesMax}
                  value={scoreConfig.minutesMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].minutesMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={scoreConfig.minutesMin}
                  max={0}
                  value={scoreConfig.minutesMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].minutesMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  max={scoreConfig.countMax}
                  value={scoreConfig.countMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].countMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={scoreConfig.countMin}
                  max={10000}
                  value={scoreConfig.countMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].countMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  value={scoreConfig.scoreOnMatch}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].scoreOnMatch = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScoreConfig(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
