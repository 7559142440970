import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreConfigTemplate = { codeLanguage: "", score: 0.0 };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
  }, [config]);

  const addScore = () => {
    updateData((d) => {
      d.scoreConfigs.push(structuredClone(scoreConfigTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((d) => {
      d.scoreConfigs.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table table-with-button">
        <thead>
          <tr>
            <th>Language code</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={() => addScore()}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scoreConfigs.map((sc, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={sc.codeLanguage}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].codeLanguage = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  step={0.1}
                  value={sc.score}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scoreConfigs[index].score = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
