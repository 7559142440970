import ApiConnector from "../connector/ApiConnector";

const Component = {
  createConfig: async function (config, latestConfigId) {
    const ok = window.confirm(
      "New Rules config will be created and set as active.",
    );
    if (!ok) {
      return;
    }
    const isConfigOutOfSync =
      latestConfigId !== (await ApiConnector.getLatestConfig()).id;
    if (isConfigOutOfSync) {
      const ok = window.confirm(
        "Saving this changes will probably overwrite changes made by somebody else. Do you want to proceed?",
      );
      if (!ok) {
        return;
      }
    }

    return await ApiConnector.createConfig(config);
  },
};

export default Component;
