import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import { useEffect, useRef, useState } from "react";
import UtilService from "../service/UtilService";
import moment from "moment";
import GetFileViaS3 from "../component/S3FileAnchor";

const Component = (props) => {
  const EXPORT_TO_CSV_DEFAULT_RANGE_HOURS = 3;
  const [data, updateData] = useImmer([]);
  const [exportToCsvUrl, updateExportToCsvUrl] = useImmer(null);
  const [filterConfig, updateFilterConfig] = useImmer({
    trnDateMax: "",
    trnDateMin: moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00"),
    totalRiskMin: null,
    totalRiskMax: null,
    transactionTypes: ["A"],
    pans: [],
    merchantCodes: [],
    statuses: [],
    riskEvaluationStatuses: [],
    rejectReasonCodes: [],
    transactionIds: [],
    isFraud: false,
    refreshSeconds: 10,
    pageSize: 20,
    pageNumber: 0,
  });
  const loadTransactionsBgJob = useRef(null);
  const [isLoadInProgress, updateIsLoadInProgress] = useImmer(false);

  /**
   * Defines component unmount
   */
  useEffect(() => {
    return () => {
      console.log(
        "Clearing background job #: " + loadTransactionsBgJob.current,
      );
      clearInterval(loadTransactionsBgJob.current);
    };
  }, []);

  useEffect(() => {
    loadTransactions();
    startAutoRefreshBgJob();
  }, [filterConfig]);

  const startAutoRefreshBgJob = () => {
    const bgJob = setInterval(
      () => loadTransactions(),
      filterConfig.refreshSeconds * 1000,
    );
    clearInterval(loadTransactionsBgJob.current);
    loadTransactionsBgJob.current = bgJob;
  };

  const loadTransactions = async () => {
    console.log("Load transactions. ", filterConfig.pageNumber);
    while (isLoadInProgress) {
      await UtilService.sleep(1);
    }
    updateIsLoadInProgress(true);
    try {
      const transactions = await ApiConnector.getTransactions({
        pageSize: filterConfig.pageSize,
        offset: filterConfig.pageNumber * filterConfig.pageSize,
        trnDateMin: filterConfig.trnDateMin
          ? filterConfig.trnDateMin + "Z"
          : null,
        trnDateMax: filterConfig.trnDateMax
          ? filterConfig.trnDateMax + "Z"
          : null,
        totalRiskMin: filterConfig.totalRiskMin,
        totalRiskMax: filterConfig.totalRiskMax,
        transactionTypes:
          filterConfig.transactionTypes.length > 0
            ? filterConfig.transactionTypes
            : null,
        pans: filterConfig.pans.length > 0 ? filterConfig.pans : null,
        merchantCodes:
          filterConfig.merchantCodes.length > 0
            ? filterConfig.merchantCodes
            : null,
        statuses:
          filterConfig.statuses.length > 0 ? filterConfig.statuses : null,
        riskEvaluationStatuses:
          filterConfig.riskEvaluationStatuses.length > 0
            ? filterConfig.riskEvaluationStatuses
            : null,
        rejectReasonCodes:
          filterConfig.rejectReasonCodes.length > 0
            ? filterConfig.rejectReasonCodes
            : null,
        transactionIds:
          filterConfig.transactionIds.length > 0
            ? filterConfig.transactionIds
            : null,
        isFraud: filterConfig.isFraud ? true : null,
      });
      updateData(transactions.data);
    } finally {
      updateIsLoadInProgress(false);
    }
  };

  const createCsv = () => {
    const trnDateMin = filterConfig.trnDateMin
      ? filterConfig.trnDateMin + "Z"
      : moment()
          .utcOffset(0)
          .subtract(EXPORT_TO_CSV_DEFAULT_RANGE_HOURS, "hours")
          .toISOString();
    const trnDateMax = filterConfig.trnDateMax
      ? filterConfig.trnDateMax + "Z"
      : moment().utcOffset(0).toISOString();

    const isMaxRangeBreached =
      moment(trnDateMax).valueOf() - moment(trnDateMin).valueOf() >
      1000 * 3600 * 24 * 40; // 40 days

    if (isMaxRangeBreached) {
      window.alert("Maximum range for CSV export can be 40 days.");
      return;
    }

    const presignedUrl = ApiConnector.generateRiskEvaluationCsvToS3(
      trnDateMin,
      trnDateMax,
    );

    return presignedUrl;
  };

  const setPageNumber = (value) => {
    updateFilterConfig((d) => {
      d.pageNumber = Number.parseInt(value);
      d.pageNumber = d.pageNumber > 500 ? 500 : d.pageNumber;
      d.pageNumber = d.pageNumber < 0 ? 0 : d.pageNumber;
    });
  };

  return (
    <>
      <header className="subheader">
        <h1>Report</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Transactions</h2>
          </div>
          <div className="col">
            <h2 className="float-right">
              <a
                className="fa-solid fa-angles-left"
                onClick={(e) => setPageNumber(filterConfig.pageNumber + 10)}
              />
              &nbsp;&nbsp;
              <a
                className="fa-solid fa-angle-left  "
                onClick={(e) => setPageNumber(filterConfig.pageNumber + 1)}
              />
              &nbsp;&nbsp;
              <a
                className={
                  "fa-solid fa-angle-right " +
                  (filterConfig.pageNumber === 0 ? "gray" : "")
                }
                onClick={(e) => setPageNumber(filterConfig.pageNumber - 1)}
              />
              &nbsp;&nbsp;
              <a
                className={
                  "fa-solid fa-angles-right " +
                  (filterConfig.pageNumber === 0 ? "gray" : "")
                }
                onClick={(e) => setPageNumber(filterConfig.pageNumber - 10)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                className={
                  !isLoadInProgress
                    ? "fa-solid fa-arrows-rotate "
                    : "fas fa-sync fa-spin gray"
                }
                onClick={(e) => loadTransactions()}
              />
            </h2>
          </div>
        </div>

        <details>
          <summary>Filtering parameters</summary>
          <table className="config-table">
            <thead>
              <tr>
                <th>Filter</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Date-time range [min - max UTC]</td>
                <td>
                  <input
                    type="datetime-local"
                    max={filterConfig.trnDateMax}
                    value={filterConfig.trnDateMin}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.trnDateMin = e.target.value;
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="datetime-local"
                    min={filterConfig.trnDateMin}
                    value={filterConfig.trnDateMax}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.trnDateMax = e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Total risk [min - max]</td>
                <td>
                  <input
                    type="number"
                    max={filterConfig.totalRiskMax}
                    value={filterConfig.totalRiskMin}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.totalRiskMin = e.target.value;
                      })
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min={filterConfig.totalRiskMin}
                    value={filterConfig.totalRiskMax}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.totalRiskMax = e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Transaction types</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(
                      filterConfig.transactionTypes,
                    )}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.transactionTypes = UtilService.csvToStringArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Pans</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(filterConfig.pans)}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.pans = UtilService.csvToStringArray(e.target.value);
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Merchant codes</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(filterConfig.merchantCodes)}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.merchantCodes = UtilService.csvToStringArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Reject reason codes</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(
                      filterConfig.rejectReasonCodes,
                    )}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.rejectReasonCodes = UtilService.csvToStringArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Statuses</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(filterConfig.statuses)}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.statuses = UtilService.csvToStringArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Risk evaluation statuses</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(
                      filterConfig.riskEvaluationStatuses,
                    )}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.riskEvaluationStatuses = UtilService.csvToStringArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Transaction ids</td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={UtilService.arrayToCsv(filterConfig.transactionIds)}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.transactionIds = UtilService.csvToNumberArray(
                          e.target.value,
                        );
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Confirmed frauds only</td>
                <td colSpan={2}>
                  <input
                    type="checkbox"
                    checked={filterConfig.isFraud}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.isFraud = !d.isFraud;
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Page size</td>
                <td colSpan={2}>
                  <input
                    type="number"
                    min={0}
                    step={10}
                    value={filterConfig.pageSize}
                    onChange={(e) => {
                      updateFilterConfig((d) => {
                        d.pageSize =
                          e.target.value < 200 ? e.target.value : 200;
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Page number</td>
                <td colSpan={2}>
                  <input
                    type="number"
                    min={1}
                    value={filterConfig.pageNumber}
                    onChange={(e) => setPageNumber(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Refresh [s]</td>
                <td colSpan={2}>
                  <input
                    type="number"
                    value={filterConfig.refreshSeconds}
                    onChange={(e) =>
                      updateFilterConfig((d) => {
                        d.refreshSeconds =
                          e.target.value < 2 ? 2 : e.target.value;
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <GetFileViaS3
            caption={
              "Export to CSV" +
              (filterConfig.trnDateMin
                ? ""
                : " (recent " + EXPORT_TO_CSV_DEFAULT_RANGE_HOURS + " hours)")
            }
            className="float-right"
            onFirstClick={() => createCsv()}
          ></GetFileViaS3>
        </details>

        <table className="config-table-double table-scrollable">
          <thead>
            <tr>
              <th>
                Date-time <small>[UTC]</small>
              </th>
              <th>
                Time <small>[Local]</small>
              </th>
              <th>Type</th>
              <th>Total risk</th>
              <th>Risk status</th>
              <th>Pan</th>
              <th>Company id</th>
              <th>Merchant</th>
              <th>
                Quantity <small>[litres]</small>
              </th>
              <th>Odometer</th>
              <th>Reject code</th>
              <th>Fraud</th>
              <th>
                Velocity <small>[km/h]</small>
              </th>
              <th>Id</th>
              <th>Config Id</th>
              <th>Status</th>
              <th>Detail status</th>
              <th>Language code</th>
            </tr>
          </thead>
          <tbody>
            {data.map((transaction) => (
              <tr key={transaction.id}>
                <td>
                  {moment(transaction.trnDate)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td>
                  {moment.parseZone(transaction.trnDate).format("HH:mm:ss")}
                </td>
                <td>{transaction.transactionType}</td>
                <td>
                  {transaction.riskEvaluation
                    ? Math.round(transaction.riskEvaluation.totalRisk * 10) / 10
                    : "-"}
                </td>
                <td>
                  {transaction.riskEvaluationStatus
                    ? transaction.riskEvaluationStatus
                    : "-"}
                </td>
                <td>{transaction.pan}</td>
                <td>{transaction.companyId ? transaction.companyId : "-"}</td>
                <td>{transaction.merchantCode}</td>
                <td>
                  {transaction.products && transaction.products.length > 0
                    ? Math.round(transaction.products[0].quantity)
                    : "-"}
                </td>
                <td>
                  {transaction.odometer !== null ? transaction.odometer : "-"}
                </td>
                <td>
                  {transaction.rejectReasonCode
                    ? transaction.rejectReasonCode
                    : "-"}
                </td>
                <td>{transaction.isFraud ? "YES" : "-"}</td>
                <td>
                  {transaction.riskEvaluation &&
                  transaction.riskEvaluation.velocityToRecentMerchantKmh > 0
                    ? transaction.riskEvaluation.velocityToRecentMerchantKmh
                    : "-"}
                </td>
                <td>{transaction.id}</td>
                <td>
                  {transaction.riskEvaluation
                    ? transaction.riskEvaluation.configId
                    : "-"}
                </td>
                <td>{transaction.status ? transaction.status : "-"}</td>
                <td>
                  {transaction.detailStatus ? transaction.detailStatus : "-"}
                </td>
                <td>{transaction.codeLanguage}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Page number: {filterConfig.pageNumber}</p>
      </main>
    </>
  );
};

export default Component;
