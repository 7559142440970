import { useImmer } from "use-immer";
import { useEffect, useRef } from "react";
import S3Connector from "../connector/S3Connector";

const Component = ({ caption, className, onFirstClick }) => {
  const STATUS_INIT = "INIT";
  const STATUS_S3_FILE_GENERATION_IN_PROGRESS = "FILE_GENERATION_IN_PROGRESS";
  const STATUS_S3_FILE_READY = "S3_FILE_READY";

  const [status, updateStatus] = useImmer(STATUS_INIT);
  const [s3FileUrl, updateS3FileUrl] = useImmer("");

  const tryObtainS3FileUrlBgJob = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(tryObtainS3FileUrlBgJob.current);
    };
  }, []);

  const executeOnFirstClick = async () => {
    const s3FileSignedUrl = await onFirstClick();
    updateS3FileUrl(s3FileSignedUrl);
    updateStatus(STATUS_S3_FILE_GENERATION_IN_PROGRESS);
    startTryObtainS3FileBgJob(s3FileSignedUrl);
  };

  const restart = () => {
    clearInterval(tryObtainS3FileUrlBgJob.current);
    updateStatus(STATUS_INIT);
  };

  const startTryObtainS3FileBgJob = (s3FileUrl) => {
    const bgJob = setInterval(() => tryObtainS3FileUrl(s3FileUrl), 1500);
    clearInterval(tryObtainS3FileUrlBgJob.current);
    tryObtainS3FileUrlBgJob.current = bgJob;
  };

  const tryObtainS3FileUrl = async (s3FileUrl) => {
    const result = await S3Connector.doesFileExists(s3FileUrl);
    console.log("Check s3 file present.");

    if (!result) {
      return;
    }

    updateS3FileUrl(s3FileUrl);
    updateStatus(STATUS_S3_FILE_READY);
    clearInterval(tryObtainS3FileUrlBgJob.current);
  };

  return (
    <>
      {status === STATUS_INIT && (
        <a className={className} onClick={() => executeOnFirstClick()}>
          {caption}
        </a>
      )}

      {status === STATUS_S3_FILE_GENERATION_IN_PROGRESS && (
        <a className={className}>
          Preparing file <i className="fas fa-circle-notch fa-spin"></i>
        </a>
      )}

      {status === STATUS_S3_FILE_READY && (
        <a
          className={className}
          href={s3FileUrl}
          onClick={() => restart()}
          download
        >
          Download file
        </a>
      )}
    </>
  );
};

export default Component;
