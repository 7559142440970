import AuthService from "./AuthService";

const Component = {
  runLogoutOnInactivityBackgroundJob: function (maxInactivityMinutes) {
    const LAST_ACTIVITY_STORAGE = "lastActivityEpochMs";

    // Define functions

    const resetTimer = () => {
      window.localStorage.setItem(LAST_ACTIVITY_STORAGE, Date.now());
    };

    const doLogoutOnProlognedInactivity = () => {
      const lastActivityEpochMs = window.localStorage.getItem(
        LAST_ACTIVITY_STORAGE,
      );

      if (lastActivityEpochMs == null) {
        return; // It happens when it was not yet set, e.g. on re-login
      }

      const inactivityMs = Date.now() - lastActivityEpochMs;
      const maxInactivityMs = maxInactivityMinutes * 60 * 1000;
      if (inactivityMs > maxInactivityMs) {
        window.localStorage.clear(LAST_ACTIVITY_STORAGE);
        AuthService.clearToken();
        AuthService.logout();
        alert(
          "You have been logout for inactivity. (" +
            maxInactivityMinutes +
            " minutes)",
        );
      }
    };

    // Run background job

    doLogoutOnProlognedInactivity(); // Do first check immediately
    setInterval(doLogoutOnProlognedInactivity, 1000);

    // Define when to reset timer

    resetTimer(); // Do first set immediately
    document.onmousedown = resetTimer;
    document.onkeydown = resetTimer;
  },
};

export default Component;
