import ApiConnector from "./ApiConnector";

const Component = {
  /**
   * Get list of S3 files present in Upload bucket.
   */
  getListOfS3Files: async function () {
    const response = await ApiConnector.call({
      method: "GET",
      url: process.env.REACT_APP_PABLO_API + "/imports/list-s3-files",
    });
    return await response.data;
  },

  /**
   * Returns true if the given file in S3 exists.
   */
  doesFileExists: async function (s3presignedUrl) {
    const response = await fetch(s3presignedUrl, { method: "GET" });
    return response.ok;
  },

  /**
   * It calls lambda to generate link for S3 file upload.
   */
  getS3FileUploadUrl: async function (filenameToCreate) {
    const url = process.env.REACT_APP_PABLO_API_AWS
      ? process.env.REACT_APP_PABLO_API_AWS // is present only when running on localhost
      : process.env.REACT_APP_PABLO_API;
    const response = await ApiConnector.call({
      method: "GET",
      url: url + "/get-upload-url?" + filenameToCreate, // this endpoint is present only in Api Gw
    });
    return await response.data;
  },

  /**
   * It uses lambda generated S3 link to upload file.
   */
  uploadFileToS3: async function (s3UploadUrl, file) {
    let options = {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    };
    let response = await fetch(s3UploadUrl, options);
    return response.text();
  },
};

export default Component;
