import UtilService from "../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreConfigTemplate = {
    countryCode: "",
    litres: [0, 0],
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((d) => {
      d.countryLitres.push(structuredClone(scoreConfigTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((d) => {
      d.countryLitres.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History min <small>[minutes]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.minimalMinutes}
                max={-1}
                onChange={(e) =>
                  updateData((d) => {
                    d.minimalMinutes = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="config-table">
        <thead>
          <tr>
            <th>Score header</th>
            <th>Suspicious</th>
            <th>Very suspicious</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            {data.scores.map((score, index) => (
              <td key={index}>
                <input
                  type="number"
                  value={score}
                  step="0.1"
                  min={0}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index] = e.target.value;
                    })
                  }
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <table className="config-table table-with-button">
        <thead>
          <tr>
            <th>Country code</th>
            <th>
              Volume <small>[litres]</small>
            </th>
            <th></th>
            <th>
              <a className="fa-solid fa-plus" onClick={() => addScore()}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.countryLitres.map((countryLitre, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={countryLitre.countryCode}
                  onChange={(e) =>
                    updateData((d) => {
                      d.countryLitres[index].countryCode = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={countryLitre.litres[0]}
                  min={0}
                  max={countryLitre.litres[1]}
                  onChange={(e) =>
                    updateData((d) => {
                      d.countryLitres[index].litres[0] = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={countryLitre.litres[1]}
                  min={countryLitre.litres[0]}
                  onChange={(e) =>
                    updateData((d) => {
                      d.countryLitres[index].litres[1] = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
