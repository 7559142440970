const Component = {
  /**
   * It converts "daytimeRuleConfig" to "Daytime rule config"
   */
  camelCaseToReadable: function (text) {
    let str = text;
    str = str.charAt(0).toUpperCase() + str.slice(1); // "daytimeRule" -> "DaytimeRule"
    str = str.replace(/([A-Z])/g, " $1"); // "DaytimeRule" -> " Daytime Rule";
    str = str.slice(1); // " Daytime Rule" -> "Daytime Rule"
    str = str.toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1); // "daytime rule" -> "Daytime rule";
    return str;
  },
  isoDateToReadable: function (isoDateStr) {
    const date = new Date(isoDateStr);
    return date.toLocaleString();
  },
  arrayToCsv: function (array, isToUpperCase) {
    return isToUpperCase ? array.toString().toUpperCase() : array.toString();
  },
  csvToStringArray: function (csvString) {
    return csvString ? csvString.split(",") : [];
  },
  csvToNumberArray: function (csvString) {
    return csvString ? csvString.split(",").map((str) => Number(str)) : [];
  },
  toHoursRemaining: function (isoOffsetDateTimeString) {
    const now = Date.now();
    console.log(now);
    let date = new Date(isoOffsetDateTimeString);
    console.log(date);
    const diffMillis = date - now;
    return Math.round((diffMillis / 1000 / 3600) * 10) / 10;
  },
  toIsoOffsetDateTimeString: function (hoursRemaining) {
    const now = new Date();
    now.setTime(now.getTime() + hoursRemaining * 3600 * 1000);
    return now.toISOString();
  },
  convertJsonArrayToCsv: function (jsons) {
    const items = jsons;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) =>
            row[fieldName] !== null ? JSON.stringify(row[fieldName]) : null,
          )
          .join(","),
      ),
    ].join("\r\n");
    return csv;
  },
  convertJsonArrayToDownloadableCsvFileUrl: function (jsons) {
    const csv = this.convertJsonArrayToCsv(jsons);
    const csvBlob = new Blob([csv], {
      type: "text/csv",
    });
    return window.URL.createObjectURL(csvBlob);
  },
  sleep: function (ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  localeCompare(a, b) {
    a = a || "";
    b = b || "";
    return a.localeCompare(b);
  },
};

export default Component;
