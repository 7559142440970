const Component = {
  getParam: function (location, parameterName) {
    const search = new URLSearchParams(location.search);
    return search.get(parameterName);
  },
  setParam: function (location, parameterName, value) {
    const search = new URLSearchParams(location.search);
    search.set(parameterName, value);
    return location.pathname + "?" + search.toString();
  },
  deleteParam: function (location, parameterName) {
    const search = new URLSearchParams(location.search);
    search.delete(parameterName);
    return location.pathname + "?" + search.toString();
  },
};

export default Component;
