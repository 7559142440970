import UtilService from "../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(config);
  }, [config]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                min={-365}
                max={data.daysMax - 1}
                value={data.daysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.daysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                min={data.daysMin + 1}
                max={0}
                value={data.daysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.daysMax = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Score on new monitored country visit</td>
            <td>
              <input
                  type="number"
                  step="0.1"
                  value={data.onNewCountryVisitScore}
                  onChange={(e) =>
                      updateData((d) => {
                        d.onNewCountryVisitScore = e.target.value;
                      })
                  }
              />
            </td>
          </tr>
          <tr>
            <td>
              Monitored countries <small>[country code]</small>
            </td>
            <td>
              <input
                  type="text"
                  value={UtilService.arrayToCsv(data.monitoredCountries)}
                  onChange={(e) =>
                      updateData((d) => {
                        d.monitoredCountries = UtilService.csvToStringArray(
                            e.target.value,
                        );
                      })
                  }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
