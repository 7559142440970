import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreConfigTemplate = {
    velocityKmhMin: 0,
    velocityKmhMax: 0,
    scoreOnMatch: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreConfigTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>
              Min <small>[km/h]</small>
            </th>
            <th>
              Max <small>[km/h]</small>
            </th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={() => addScore()}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  value={score.velocityKmhMin}
                  min={0}
                  max={score.velocityKmhMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].velocityKmhMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.velocityKmhMax}
                  min={score.velocityKmhMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].velocityKmhMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  value={score.scoreOnMatch}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].scoreOnMatch = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
