import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const toggleEnabled = () => {
    updateData((d) => {
      d.isNotificationEnabled = !d.isNotificationEnabled;
    });
  };

  return (
    <article>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Notifications enabled</td>
            <td>
              <input
                type="checkbox"
                checked={data.isNotificationEnabled}
                onChange={(e) => toggleEnabled()}
              />
            </td>
          </tr>
          <tr>
            <td>Send Basic notification - max consecutive amount</td>
            <td>
              <input
                type="number"
                value={data.authDeclinedNotificationMaxSentCount}
                onChange={(e) =>
                  updateData((d) => {
                    d.authDeclinedNotificationMaxSentCount = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Send Basic notification - delay [ms]</td>
            <td>
              <input
                type="number"
                value={data.authDeclinedNotificationDelayMs}
                onChange={(e) =>
                  updateData((d) => {
                    d.authDeclinedNotificationDelayMs = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Send Aggregated notification - min attempt count</td>
            <td>
              <input
                type="number"
                value={data.authDeclinedRepeatedlyNotificationAfterCount}
                onChange={(e) =>
                  updateData((d) => {
                    d.authDeclinedRepeatedlyNotificationAfterCount =
                      e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Send Aggregated notification - delay [ms]</td>
            <td>
              <input
                type="number"
                value={data.authDeclinedRepeatedlyNotificationDelayMs}
                onChange={(e) =>
                  updateData((d) => {
                    d.authDeclinedRepeatedlyNotificationDelayMs =
                      e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Send Card locked notification - delay [ms]</td>
            <td>
              <input
                type="number"
                value={data.authDeclinedAndCardLockedNotificationDelayMs}
                onChange={(e) =>
                  updateData((d) => {
                    d.authDeclinedAndCardLockedNotificationDelayMs =
                      e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </article>
  );
};

export default Component;
