const Component = {
  isThisPageRedirectionFromLoginPage: function () {
    return window.location.href.includes("id_token");
  },
  setToken: function (token) {
    window.localStorage.setItem("token", token);
  },
  getToken: function () {
    return window.localStorage.getItem("token");
  },
  clearToken: function () {
    window.localStorage.removeItem("token");
  },
  logout: function () {
    this.clearToken();
    window.location.href = process.env.REACT_APP_COGNITO_LOGOUT;
  },
  getTokenPayload: function () {
    const encodedToken = this.getToken();
    return encodedToken != null
      ? this.mapEncodedTokenToPayloadJson(encodedToken)
      : null;
  },
  redirectToLoginPage: function () {
    window.location.href = process.env.REACT_APP_COGNITO_LOGIN;
  },
  getIdTokenFromUrl: function () {
    const params = window.location.href.split("#")[1].split("&");
    for (const p of params) {
      if (p.includes("id_token")) {
        return p.split("=")[1];
      }
    }
    return null;
  },
  mapEncodedTokenToPayloadJson: function (token) {
    if (!token) {
      return;
    }
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  },
  getUserName: function () {
    return this.getTokenPayload().email.split("@")[0];
  },
};

export default Component;
