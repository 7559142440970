import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Merchant classification</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Hotspot</td>
            <td>
              <input
                type="number"
                value={data.hotspotScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.hotspotScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Semi-hotspot</td>
            <td>
              <input
                type="number"
                value={data.semiHotspotScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.semiHotspotScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/config/hotspot">View detail</Link>
    </>
  );
};

export default Component;
