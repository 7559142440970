import ApiConnector from "../connector/ApiConnector";

const Component = {
  createGeneralConfig: async function (generalConfig, latestGeneralConfigId) {
    const result = window.confirm(
      "New general config will be created and set as active.",
    );
    if (!result) {
      return;
    }
    const isConfigOutOfSync =
      latestGeneralConfigId !==
      (await ApiConnector.getLatestGeneralConfig()).id;
    if (isConfigOutOfSync) {
      const ok = window.confirm(
        "Saving this changes will probably overwrite changes made by somebody else. Do you want to proceed?",
      );
      if (!ok) {
        return;
      }
    }

    return await ApiConnector.createGeneralConfig(generalConfig);
  },
};

export default Component;
