import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import UtilService from "../service/UtilService";
import { useEffect, useState } from "react";
import moment from "moment";

const Component = (props) => {
  const [refuelingStatistics, updateRefuelingStatistics] = useImmer(null);

  useEffect(async () => {
    const result = await ApiConnector.getPanVolumesWithFrauds();
    result.sort((a, b) => b.mean - a.mean);
    updateRefuelingStatistics(result);
  }, []);

  return (
    <>
      <header className="subheader">
        <h1>Config - Volume history rule</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Volume statistics for fraudulent Pans</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right"></h2>
          </div>
        </div>
        {refuelingStatistics && (
          <>
            <details>
              <summary>Parameters</summary>
              <table>
                <tbody>
                  <tr>
                    <td>Transtaction date min - max [UTC]</td>
                    <td>Past 1 year</td>
                  </tr>
                  <tr>
                    <td>Fuel codes</td>
                    <td>All except Ad blue</td>
                  </tr>
                </tbody>
              </table>
            </details>

            <table className="config-table-double table-scrollable">
              <thead>
                <tr>
                  <th>Pan</th>
                  <th>
                    Mean - non-fraud <small>[L]</small>
                  </th>
                  <th>
                    Std. dev. <small>[L]</small>
                  </th>
                  <th>
                    Max <small>[L]</small>
                  </th>
                  <th>Refuelling count</th>
                  <th>
                    Fraud volumes <small>[Date : L]</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {refuelingStatistics.map((statistic, idx) => (
                  <tr key={idx}>
                    <td>{statistic.pan}</td>
                    <td>{statistic.mean}</td>
                    <td>{statistic.stdDev}</td>
                    <td>{statistic.max}</td>
                    <td>{statistic.refuelingCount}</td>
                    <td>
                      {statistic.fraudVolumes.map((v, idx2) => (
                        <span key={idx2}>
                          <small>
                            {moment(v.date).format(moment.HTML5_FMT.DATE)}:
                          </small>{" "}
                          <b>{v.volume}</b> {", "}
                        </span>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
